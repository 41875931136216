import request from '@/utils/http'

const subjectApi = {
  subject: 'api/wechat/assessment/subject',
  answer: '/api/wechat/assessment/subject/answer',
}

// 获取全部题目
export function getSubject(parameter) {
  return request({
    url: subjectApi.subject,
    method: 'get',
    params: parameter,
  })
}
// 回传题目
export function postAnswer(parameter) {
  return request({
    url: subjectApi.answer,
    method: 'post',
    data: parameter,
  })
}
