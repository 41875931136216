<template>
  <div class="subject">
    <BaseNavbar pagename="答题"></BaseNavbar>
    <div class="subject-content">
      <h1>
        {{ currentlist.questionStem }}
      </h1>
      <p class="content-exm">
        {{ currentlist.instance }}
      </p>
      <div class="content-title">
        <div>多选题</div>
        <p>请选出以下符合儿童情况的所有选项</p>
      </div>

      <div class="content-item">
        <div
          :class="{
            'item-option': true,
            'option-disabled': isOnly,
            'option-yes': isChoice(item),
          }"
          v-for="(item, ix) in options"
          :key="ix"
          @click="choiceOption(item)"
        >
          <div></div>
          <p>{{ item.description }}</p>
        </div>

        <div class="item-phase">
          <span>{{ parseInt(currentId) + 1 }}</span
          >/{{ subjectList.length }}
        </div>
      </div>
    </div>

    <div class="subject-button">
      <div class="button-next" v-show="currentId == 0">
        <div @click="gonext()">下一题</div>
      </div>
      <div class="button-all" v-show="currentId != 0 && currentId != subjectList.length - 1">
        <div @click="gopre()">上一题</div>
        <div @click="gonext()">下一题</div>
      </div>
      <div class="button-submit" v-show="currentId == subjectList.length - 1">
        <div @click="submitpre()">上一题</div>
        <div class="submit-bg" @click="submit()">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { postAnswer } from '@/api/subject'
import BaseNavbar from '@/components/BaseNavbar'
import { Toast } from 'vant'
export default {
  name: 'Subject',
  components: { BaseNavbar },
  data() {
    return {
      // 选择的选项
      choicedArr: {
        subjectId: null,
        subjectOptions: [],
      },
      allList: [],
      currentId: null,
      currentlist: {},
      isOnly: false,
    }
  },
  created() {
    this.querySubject()
  },
  computed: {
    ...mapState(['subjectList', 'answer']),
    // 当前题目的选项重新排序
    options() {
      const current = this.currentlist
      return current.assessmentSubjectOptionList.sort((a, b) => b.order - a.order)
    },
  },
  methods: {
    ...mapActions(['actanswer']),
    ...mapMutations(['setSubject']),
    // 按照编号进行答题
    querySubject() {
      // 设置参数
      this.isOnly = false
      this.allList = this.subjectList
      this.currentId = this.$route.params.id
      const temp = this.allList.filter((item, index) => {
        return index == this.currentId
      })
      this.currentlist = temp[0]
      this.choicedArr.subjectId = this.currentlist.id
      this.choicedArr.subjectOptions = []
      this.currentlist.assessmentSubjectOptionList.forEach(opt => {
        if (opt.isSelect == true) {
          this.choicedArr.subjectOptions.push(opt.id)
        }
      })
    },
    // 当前题目的选项操作
    choiceOption(item) {
      // isonly = false 没有选’以上都不具备‘选项
      if (!this.isOnly) {
        if (item.order == 0) {
          // 选中以上都不具备选项
          this.isOnly = true
          this.choicedArr.subjectOptions = []
          this.choicedArr.subjectOptions.push(item.id)
        } else if (this.isChoice(item)) {
          // 取消其他选项选中
          const index = this.choicedArr.subjectOptions.findIndex(isitem => {
            return isitem == item.id
          })
          this.choicedArr.subjectOptions.splice(index, 1)
        } else {
          // 选中其他的选项
          this.choicedArr.subjectOptions.push(item.id)
        }
      } else {
        if (item.order == 0) {
          // 取消选中以上都不具备选项
          this.isOnly = false
          const index = this.choicedArr.subjectOptions.findIndex(onlyitem => {
            return onlyitem == item.id
          })
          this.choicedArr.subjectOptions.splice(index, 1)
        }
      }
    },
    // 实时判断选项是否选中
    isChoice(opt) {
      if (this.choicedArr.subjectOptions.includes(opt.id)) {
        if (opt.order == 0) {
          this.isOnly = true
        } else {
          this.isOnly = false
        }
        return true
      } else {
        return false
      }
    },
    // 保存答题记录
    setChoice() {
      // 修改本地题目的选择
      this.currentlist.isAnswer = true
      this.currentlist.assessmentSubjectOptionList.forEach(item => {
        if (this.choicedArr.subjectOptions.includes(item.id)) {
          item.isSelect = true
        } else {
          item.isSelect = false
        }
      })
      this.allList.splice(parseInt(this.currentId), 1, this.currentlist)
      this.setSubject(this.allList)
      this.actanswer()
    },
    // 返回上一题
    gopre() {
      this.setChoice()
      const temp = this.answer
      postAnswer(temp).then(res => {
        if (res.code == 0) {
          this.$router.push({
            name: 'subject',
            params: { id: parseInt(this.currentId) - 1 },
          })
        }
      })
    },
    // 跳转下一题
    gonext() {
      if (this.choicedArr.subjectOptions.length == 0) {
        Toast('请选择选项')
      } else {
        this.setChoice()
        const temp = this.answer
        postAnswer(temp).then(res => {
          if (res.code == 0) {
            this.$router.push({
              name: 'subject',
              params: { id: parseInt(this.currentId) + 1 },
            })
          }
        })
      }
    },
    // 提交页面的返回上一题
    submitpre() {
      this.setChoice()
      this.$router.push({
        name: 'subject',
        params: { id: parseInt(this.currentId) - 1 },
      })
    },
    // 提交
    submit() {
      if (this.choicedArr.subjectOptions.length == 0) {
        Toast('请选择选项')
      } else {
        this.setChoice()
        const temp = this.answer
        postAnswer(temp).then(res => {
          if (res.code == 0) {
            // 打开结果页
            this.$router.push({
              name: 'result',
              query: { fieldid: 2021030432 },
            })
          }
        })
      }
    },
  },
  watch: {
    $route() {
      this.querySubject()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.subject {
  min-height: 100vh;
  margin: 44px 0 0;
  background-color: #f8f8f8;
  overflow: hidden;

  .subject-content {
    width: 343px;
    margin: 16px auto;
    background: #ffffff;
    border-radius: 16px;
    overflow: hidden;

    h1 {
      width: 92%;
      margin: 16px auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #000000;
    }

    .content-exm {
      width: 92%;
      margin: 16px auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }

    .content-title {
      display: flex;
      align-items: center;
      width: 92%;
      height: 30px;
      margin: 0 auto 12px;

      div {
        width: 46px;
        margin-right: 10px;
        padding: 2px 0;
        color: #ffffff;
        font-size: 12px;
        text-align: center;
        background: #3350c7;
        border-radius: 3px;
      }

      p {
        width: 80%;
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
    }

    .content-item {
      width: 92%;
      margin: 0 auto;

      .item-option {
        display: flex;
        align-items: center;
        width: 311px;
        margin: 12px 0;
        padding: 12px 0;
        border: 1px solid #e6e6e6;
        border-radius: 8px;

        div {
          width: 15px;
          height: 15px;
          margin: 0 12px;
          border: 1px solid #979797;
          border-radius: 50%;
        }

        p {
          width: 80%;
          margin: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.8);
        }
      }
      // 选项冻结
      .option-disabled {
        div {
          width: 15px;
          height: 15px;
          margin: 0 12px;
          border: 1px solid #979797;
          background-color: #ebedf0;
          border-radius: 50%;
        }
        p {
          color: #c8c9cc;
        }
      }
      // 选项选中
      .option-yes {
        color: #ffffff;
        background-color: #3350c7;

        div {
          border: 1px solid #ffffff;
          background: url(~@/assets/image/ico_choice.png) center no-repeat;
          background-size: 500%;
        }

        p {
          color: #ffffff;
        }
      }

      .item-phase {
        margin: 16px 0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-align: center;

        span {
          color: #3350c7;
        }
      }
    }
  }
  .subject-button {
    width: 327px;
    margin: 45px auto;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);

    .button-next {
      div {
        height: 40px;
        border: 1px solid #303031;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
      }
    }
    .button-all {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        height: 40px;
        min-width: 45%;
        border: 1px solid #303031;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
      }
    }
    .button-submit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        height: 40px;
        min-width: 45%;
        border: 1px solid #303031;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
      }
      .submit-bg {
        border: none;
        background: #4760c6;
        color: #ffffff;
      }
    }
  }
}
</style>
